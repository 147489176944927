import { useRef } from "react";
import { UserAgentType } from "../../common/common.inerface";
import "./css/index.css"
import useScrollFade from "../../hook/UseFade";

type Prop = {
    idx: number;
    title: string;
    img:string;
    subtitle: string;
    userAgent:UserAgentType;
}
const Card = ({ idx, title, subtitle,img , userAgent}: Prop) => {
    const scrollRef = useRef(null);
    useScrollFade(0.2, scrollRef)

    return (
        <div key={idx} className='card' ref={scrollRef}>
            <img src={`/assets/section5/${userAgent}/${img}${idx}.png`}
                className="sub-img"
                srcSet={`
                    /assets/section5/${userAgent}/${img}${idx}@2x.png 2x,
                    /assets/section5/${userAgent}/${img}${idx}@3x.png 3x,
                  `}
                alt="" />
            <div className='card-title-wrapper'>
                <span className='card-title'>{title}</span>
                <span className='card-subtitle'>{subtitle}</span>
            </div>
            <div className='img-wrapper'>
                <img src={`/assets/section5/${userAgent}/${img}${idx}-${idx}.png`}
                    srcSet={`
                    /assets/section5/${userAgent}/${img}${idx}-${idx}@2x.png 2x,
                    /assets/section5/${userAgent}/${img}${idx}-${idx}@3x.png 3x,
                  `}
                    alt="" />
            </div>
        </div>
    )
};

export default Card;