import React, { useRef } from "react";
import "./css/index.css";
import { UserAgentType } from "../../../common/common.inerface";
import Card from "../../Card/Card";
import useScrollFade from "../../../hook/UseFade";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducer";
import { LangType } from "../../../reducer/config";
type Props = {
  userAgent: UserAgentType;
};

const Section5 = ({ userAgent }: Props) => {
  const scrollRef = useRef(null);
  const lang = useSelector<RootState, LangType>((state) => state.config.lang);
  useScrollFade(0, scrollRef);
  const leftCardList = [
    {
      img: "left",
      title: `카메라에 잘 보이지 않더라도 
영향을 받지 않습니다`,
      engTitle: `Maintains accuracy even
when not fully visible to the camera`,
      subtitle:
        userAgent === "pc" || userAgent === "tablet"
          ? `독자적인 알고리즘을 통해 손의 일부만 보여도 
가려진 부분까지 정밀한 트래킹이 가능합니다.`
          : `독자적인 알고리즘을 통해 손의 일부만 
보여도 가려진 부분까지 정밀한 트래킹이 가능합니다.`,
      engSub: `Our proprietary algorithm allows for precise
tracking of the entire hand, even if only part of it is visible to the camera.`,
    },
    {
      img: "left",
      title: `외부 자기장의 
영향을 받지 않습니다.`,
      engTitle: `Unaffected by external magnetic fields`,
      subtitle:
        userAgent === "pc" || userAgent === "tablet"
          ? `자기장 환경에서 트래킹이 불가하던 기존 여러 
트래킹 기술의 한계를 뛰어넘어 외부 자기장의 
영향으로부터 자유롭습니다.`
          : `자기장 환경에서 트래킹이 불가하던 기존 
여러 트래킹 기술의 한계를 뛰어넘어 
외부 자기장의 영향으로부터 자유롭습니다.`,
      engSub: `Our technology overcomes the limitations of
previous tracking systems, which were unable to function in magnetic environments, 
by maintaining stability despite magnetic influences.`,
    },
  ];

  const rightCardList = [
    {
      img: "right",
      title: `빛의 밝기에 
영향을 받지 않습니다.`,
      engTitle: `Unaffected by lighting conditions`,
      subtitle: `밝거나 어두운 환경 등 공간의 밝기 관계없이 
높은 인식 정확도를 제공합니다.`,
      engSub: `Our technology maintains high recognition
accuracy regardless of environmental lighting conditions.`,
    },
    {
      img: "right",
      title: `다양한 오브젝트와 사용하여도
문제 없습니다.`,
      engTitle: `Performs seamlessly with various objects`,
      subtitle: `다양한 오브젝트와 사용하여도 오브젝트에 
방해받지 않고 사용할 수 있습니다.`,
      engSub: `Can be used with various objects without
interference or performance loss.`,
    },
  ];
  return (
    <section className="section5">
      <div className="section5-title-wrapper" ref={scrollRef}>
        <button className="title-btn">
          {lang === "KOR" ? "퀘스터의 기술력" : "Quester’s Technology"}
        </button>
        {lang === "KOR" && (
          <>
            <div className="title">
              퀘스터는 <span>높은 정확도</span>를 제공하며{" "}
            </div>
            <div className="title">
              외부 교란에도 강한 {userAgent === "mb" && <br />} 핸드 트래킹
              솔루션을 개발합니다.
            </div>
          </>
        )}
        {lang === "ENG" && (
          <>
            <div className="title">
              Quester develops{userAgent !== "pc" && <br />} hand tracking{" "}
              {userAgent === "mb" && <br />}
              solutions that offer
            </div>
            <div className="title">
              high accuracy and are {userAgent === "mb" && <br />} robust
              against external disturbances
            </div>
          </>
        )}
      </div>
      <div className="section5-card-wrapper">
        <div className="left-card">
          {leftCardList.map(
            ({ title, subtitle, img, engTitle, engSub }, idx) => (
              <Card
                userAgent={userAgent}
                img={img}
                title={lang === "KOR" ? title : engTitle}
                subtitle={lang === "KOR" ? subtitle : engSub}
                idx={idx}
                key={idx}
              />
            )
          )}
        </div>
        <div className="right-card">
          {rightCardList.map(
            ({ img, title, subtitle, engTitle, engSub }, idx) => (
              <Card
                userAgent={userAgent}
                img={img}
                title={lang === "KOR" ? title : engTitle}
                subtitle={lang === "KOR" ? subtitle : engSub}
                idx={idx}
                key={idx}
              />
            )
          )}
        </div>
      </div>
    </section>
  );
};
export default Section5;
