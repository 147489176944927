import "./css/index.css";
import { UserAgentType } from "../../../common/common.inerface";
import { useRef, useState } from "react";
import useScrollFade from "../../../hook/UseFade";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducer";
import { LangType } from "../../../reducer/config";
type Props = {
  userAgent: UserAgentType;
};

const Section3 = ({ userAgent }: Props) => {
  const lang = useSelector<RootState, LangType>((state) => state.config.lang);
  const scrollRef = useRef(null);
  const scrollRef2 = useRef(null);
  const [active, setActive] = useState<number>(0);
  useScrollFade(0, scrollRef);
  useScrollFade(0.2, scrollRef2);

  return (
    <section className="section3">
      <div className="section3-text-wrapper" ref={scrollRef}>
        <div className="section3-tag">
          {lang === "KOR"
            ? "기술 적용 예시"
            : "Technology Application Examples"}
        </div>
        {lang === "KOR" && (
          <div className="section3-title">
            교육, 게임, 의료, 군사, {userAgent === "mb" && <br />}제조, 로봇 등
            다양한{userAgent === "mb" && <br />} 산업 분야에서{" "}
            {userAgent !== "mb" && <br />}
            활용될 수 있는 {userAgent === "mb" && <br />}
            <span className="text-bold">핵심적인 원천 기술</span>을
            만들어갑니다.
          </div>
        )}
        {lang === "ENG" && (
          <div className="section3-title">
            We create{" "}
            <span className="text-bold">core proprietary technology</span>{" "}
            <br />
            applicable across diverse {userAgent === "mb" && <br />} industries
            such as <br /> education, gaming, healthcare,
            {userAgent !== "pc" && <br />} military, manufacturing,
            {userAgent === "mb" && <br />} and robotics.
          </div>
        )}
        {lang === "KOR" && (
          <div className="section3-sub">
            {userAgent === "mb"
              ? `여러 산업에 걸쳐 실제와 가상을 연결하고,
사용자와 로봇 간의 상호작용을 돕는
중추적인 역할을 수행합니다.`
              : `여러 산업에 걸쳐 실제와 가상을 연결하고,
사용자와 로봇 간의 상호작용을 돕는 중추적인 역할을 수행합니다.`}
          </div>
        )}
        {lang === "ENG" && (
          <div className="section3-sub">
            {`Our technology bridges the gap between
the real and virtual worlds and plays a pivotal role in
facilitating interactions between users and robots. `}
          </div>
        )}
      </div>
      <div className="section3-box" ref={scrollRef2}>
        <b className="section3-box-title">
          {lang === "KOR"
            ? "적용하고자 하는 분야가 있나요?"
            : "Explore Application Areas"}
        </b>
        {lang === "KOR" && (
          <div className="box-select-list">
            {layouts.map((item, idx) => {
              return (
                <button
                  onClick={() => setActive(idx)}
                  key={idx}
                  className={`box-select-btn ${idx === active && "active"}`}
                >
                  {item}
                </button>
              );
            })}
          </div>
        )}
        {lang === "ENG" && (
          <div className="box-select-list">
            {eng_layouts.map((item, idx) => {
              return (
                <button
                  onClick={() => setActive(idx)}
                  key={idx}
                  className={`box-select-btn ${idx === active && "active"}`}
                >
                  {item}
                </button>
              );
            })}
          </div>
        )}
        <div className="education-wrapper">
          <div className="education-text-wrapper">
            <b className="education-title">
              {lang === "KOR"
                ? educationArr[active].title
                : eng_educationArr[active].title}
            </b>
            <b className="education-subtitle">
              {lang === "KOR"
                ? educationArr[active].description
                : eng_educationArr[active].description}
            </b>
          </div>
          <div className="education-img">
            <img
              src={`/assets/section3/${userAgent}/field${active}.png`}
              srcSet={`
                /assets/section3/${userAgent}/field${active}@2x.png 2x,  
                /assets/section3/${userAgent}/field${active}@3x.png 3x,  
              `}
              alt="edcucation"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section3;

const layouts = [
  "의료 실습 및 과학 실험 교육",
  "항공 훈련",
  "직무 훈련",
  "군사 훈련",
  "VR 게임 조작",
  "VR 원격 협업",
  "버츄얼 유튜버",
  "XR 콘텐츠 제작",
  "재활 치료",
  "작업자 행동 분석",
  "로봇 수술 지원",
  "로봇 원격 조작",
  "로봇 학습 및 제어",
  "로봇팔 경로 학습",
];
const eng_layouts = [
  "Medical & Scientific Education",
  "Aviation Training",
  "Job Training",
  "Military Training",
  "VR Game Control",
  "VR Remote Collaboration",
  "Virtual YouTubers",
  "XR Content Creation",
  "Rehabilitation Therapy",
  "Worker Behavior Analysis",
  "Robotics Surgery Assistance",
  "Robotic Teleoperation",
  "Robot Training and Control",
  "Robot Arm Trajectory Optimization",
];

const educationArr = [
  {
    title: `의료 실습 및 과학 실험 교육`,
    description: `의료 절차 시뮬레이션, 수술 기법 훈련,
과학 실험 시뮬레이션 등에 활용하여
실전에서의 숙련도 향상`,
  },
  {
    title: "항공 훈련",
    description: `생생한 가상 비행 조종 훈련, 항공기 정비
시뮬레이션 등에 활용되어
실제와 유사한 학습 경험을 제공`,
  },
  {
    title: "직무 훈련",
    description: `신입 직원이나 기존 직원의 기술 향상을 위한
교육 도구로 활용되어 기계 조작, 정비 절차,
안전 교육 등을 가상 환경에서 위험도 없이 실시`,
  },
  {
    title: "군사 훈련",
    description: `가상 훈련을 통한 전술 훈련, 장비 사용법 교육,
전장 시뮬레이션 등에 활용되어
현실감 있는 훈련 경험 제공`,
  },
  {
    title: "VR 게임 조작",
    description: `VR 게임 속 캐릭터를 손동작을 통해 조작하게
함으로써 몰입감을 높여주는 인터페이스 제공`,
  },
  {
    title: "VR 원격 협업",
    description: `가상 회의나 원격 작업 시 손동작을 통해 
보다 직관적이고 효율적인 협업 환경 제공`,
  },

  {
    title: "버츄얼 유튜버",
    description: `손동작과 제스처를 통해 가상 캐릭터의 표현력을
향상시키며, 시청자와의 실시간 스트리밍 중에도
자연스러운 상호작용 제공`,
  },
  {
    title: "XR 콘텐츠 제작",
    description: `영화, 애니메이션, 비디오 제작 등에서 
정밀한 손 움직임을 표현하기 위한
모션 캡처 시스템으로 활용`,
  },
  {
    title: "재활 치료",
    description: `손동작 데이터를 기반으로 환자의 재활 과정을 
모니터링하고, 맞춤형 재활 프로그램 제공`,
  },
  {
    title: "작업자 행동 분석",
    description: `제조 프로세스 등을 실시간으로 분석하고 개선할 수
있도록 작업자의 손 데이터를 제공함으로써,
작업장의 효율성과 안전성을 향상`,
  },
  {
    title: "로봇 수술 지원",
    description: `정밀한 수술이나 치료 과정에서
로봇 팔을 제어하는 데 사용`,
  },
  {
    title: "로봇 원격 조작",
    description: `사용자의 손동작과 제어 신호에 따라 
로봇을 움직이게 할 수 있어 
텔레오퍼레이션 분야에서 효과적으로 활용`,
  },
  {
    title: "로봇 학습 및 제어",
    description: `로봇의 손동작 및 그리퍼 제어를 위한 정밀한 데이터
제공. 특히, 휴머노이드 로봇 기술 개발 및
학습에 있어 인간과 유사한 움직임 재현 가능`,
  },
  {
    title: "로봇팔 경로 학습",
    description: `작업 환경에서 로봇팔의 경로를 학습하고 
최적화하여 효율성을 높이는 데 활용`,
  },
];

const eng_educationArr = [
  {
    title: `Medical & Scientific Education`,
    description: `Used in medical procedure simulations, surgical technique training, and scientific experiment simulations, enhancing practical skills and proficiency`,
  },
  {
    title: "Aviation Training",
    description: `Used in realistic flight simulations and aircraft maintenance training, providing a hands-on, immersive learning experience`,
  },
  {
    title: "Job Training",
    description: `Used as an educational tool for skill enhancement of new and existing employees, enabling safe practice of machine operations, maintenance procedures, and safety education in a virtual environment`,
  },
  {
    title: "Military Training",
    description: `Utilized for tactical training, equipment usage education, and battlefield simulations through virtual reality, providing a realistic training experience`,
  },
  {
    title: "VR Game Control",
    description: `Provides an immersive interface that allows players to control characters in VR games through hand gestures`,
  },
  {
    title: "VR Remote Collaboration",
    description: `Provides a more intuitive and efficient collaboration environment during virtual meetings and remote work through hand gestures`,
  },

  {
    title: "Virtual YouTubers",
    description: `Enhances the expressiveness of virtual characters through hand gestures and improves real-time interaction with viewers during live streaming`,
  },
  {
    title: "XR Content Creation",
    description: `Utilized as a motion capture system to accurately represent hand movements in film, animation, and video production`,
  },
  {
    title: "Rehabilitation Therapy",
    description: `Monitors patients’ rehabilitation processes based on hand motion data and provides customized rehabilitation programs`,
  },
  {
    title: "Worker Behavior Analysis",
    description: `Enhances workplace efficiency and safety by leveraging real-time hand data from workers to analyze and improve manufacturing processes`,
  },
  {
    title: "Robotics Surgery Assistance",
    description: `Utilized to control robotic arms during precise surgical and treatment procedures`,
  },
  {
    title: "Robotic Teleoperation",
    description: `Enables effective teleoperation by allowing robots to be maneuvered based on user hand gestures and control signals`,
  },
  {
    title: "Robot Training and Control",
    description: `Provides precise data for controlling robot hand movements and grippers, facilitating the development and training of humanoid robot technologies to replicate human-like motion`,
  },
  {
    title: "Robot Arm Trajectory Optimization",
    description: `Utilized to learn and optimize the trajectory of robotic arms in the workplace, enhancing efficiency`,
  },
];
