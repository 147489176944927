import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../common/common.inerface";
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";
import { LangType } from "../../reducer/config";

type Props = {
  userAgent: UserAgentType;
};

const Footer = ({ userAgent }: Props) => {
  const lang = useSelector<RootState, LangType>((state) => state.config.lang);
  return (
    <footer>
      <div className="footer-content-section">
        <div className="footer-content-left">
          <div className="logo-wrapper">
            <img src="/assets/footer/logo.svg" alt="quester" />
          </div>
          <div className="footer-content">
            {lang === "KOR" && (
              <div className="footer-text">
                {" "}
                퀘스터 | 대표 이정우 {userAgent === "mb" ? <br /> : "|"}{" "}
                사업자등록번호 464-81-02569
                {userAgent !== "mb" ? <br /> : " | "}
                <span>약관 및 정책</span>
                {userAgent !== "mb" && (
                  <div className="copy-right">
                    Copyright QUESTER, All Rights Reserved
                  </div>
                )}
              </div>
            )}
            {lang === "ENG" && (
              <div className="footer-text">
                <span>Terms and conditions</span>
                {userAgent !== "mb" && (
                  <div className="copy-right">
                    Copyright QUESTER, All Rights Reserved
                  </div>
                )}
              </div>
            )}
            <div className="footer-list">
              {layouts.map(({ img, content, eng }, idx) => {
                return (
                  <div key={idx} className="list-content">
                    <div className="list-img">
                      <img src={`/assets/footer/${img}.svg`} alt={img} />
                    </div>
                    <div className="list-content">
                      {lang === "KOR" ? content : eng}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {userAgent === "mb" && (
            <div className="copy-right">
              Copyright QUESTER, All Rights Reserved
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const layouts = [
  {
    img: "place",
    content: `대구광역시 달성군 현풍읍
테크노중앙대로 333, R7 (산학협력관)`,
    eng: `#203-B, R7 (Industry-Academia Cooperation Building), 333, Techno jungang-daero,
Hyeonpung-eup, Dalseong-gun, Daegu, Republic of Korea`,
  },

  {
    img: "tel",
    content: "070-4458-5995",
    eng: `(+82) 70-4458-5995`,
  },
  {
    img: "mail",
    content: "quester@quester.kr",
    eng: `quester@quester.kr`,
  },
];
